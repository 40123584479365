import { extendTheme, ThemeConfig } from '@chakra-ui/react';
// import { fonts } from './fonts';
// import { colors } from './colors';
// import { components } from './components';
// import { styles } from './styles';
import { semanticTokens } from './semanticTokens';

export const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'sensai',
};

export const theme = extendTheme({
  config,
  // colors,
  semanticTokens,
  // styles,
  // fonts,
  // components,
  // sizes: { xl: '54rem' },
});
